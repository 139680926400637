export const users: TableModule = {
    name: "users",
    route: "spa.users",
    defaultOrder: {
        column: "lastname|firstname",
        direction: "asc"
    },
    columns: [
        {
            name: "customName",
            sortable: true,
            pageLink: true,
            sortBy: "lastname|firstname",
            mobile: true,
            width: "w-1/2 sm:w-auto"
        },
        {
            name: "email",
            sortable: true
        },
        {
            name: "role_name",
            sortable: true,
            mobile: true
        },
        {
            name: "created_at",
            sortable: true,
            breakpoint: "md"
        }
    ],
    filters: [
        {
            name: "id",
            column: "users.id",
            type: "numeric"
        },
        {
            name: "firstname",
            column: "users.firstname",
            type: "string"
        },
        {
            name: "email",
            column: "users.email",
            type: "string"
        },
        {
            name: "role",
            column: "users.role_name",
            type: "select",
            options: [
                {
                    value: "admin",
                    label: "users.roles.admin"
                },
                {
                    value: "user",
                    label: "users.roles.user"
                }
            ]
        }
    ],
    quickFilters: [
        {
            name: "qf_onlyEmployees",
            column: "users.role_name",
            value: [
                "user"
            ],
            operator: "in"
        }
    ],
    tools: [
        {
            name: "general.export",
            icon: "heroicons:arrow-down-tray"
        },
        {
            name: "users.delete",
            color: "rose",
            icon: "heroicons:trash"
        },
        {
            name: "users.create",
            isCreateAction: true,
            ability: [
                "create",
                "users"
            ]
        }
    ]
}