import {users} from './tableModules/users'
import {organisations} from "./tableModules/organisations";
import {loginProviders} from './tableModules/loginProviders'
import {contacts} from './tableModules/contacts'

export const tableModules: TableModules = {
    users,
    organisations,
    testUsers: {
        ...users,
        name: 'testUsers'
    } as TableModule,
    contacts,
    loginProviders
}
